import { Dinero } from "dinero.js";
import React, { useEffect, useMemo, useState } from "react";
import { FinancingModalTrigger } from "tsi-common-react/src/apps/financing/FinancingModalTrigger";
import { useAppSelector } from "tsi-common-react/src/apps/reducers";
import { Link } from "tsi-common-react/src/common/Link";
import {
    FinancingPlanMeta,
    getDefaultFinancingPlan,
    getLongestActivePlan,
} from "tsi-common-react/src/utils/financing";
import { format, formatFinancingPlan } from "tsi-common-react/src/utils/format";
import { getDinero, getMonthlyPrice } from "tsi-common-react/src/utils/money";
import { urls } from "tsi-common-react/src/utils/urls";
import { t } from "ttag";

import tempurCreditCardUpdated from "../../img/checkout/tempur-credit-card-updated.png";

interface IProps {
    applicationSource?: string;
}

export const FinancingModalTriggerHomepage = (props: IProps) => {
    const financingPlans = useAppSelector(
        (state) => state.checkout.data.financing_plans,
    );
    const [plan, setPlan] = useState<FinancingPlanMeta | null>(null);
    const defaultPlan = useMemo(getDefaultFinancingPlan, []);

    useEffect(() => {
        const getPlan = async () => {
            const longestPlan = await getLongestActivePlan(financingPlans);
            setPlan(longestPlan || defaultPlan);
        };
        getPlan();
    }, [plan]);

    const formattedPlan = useMemo(() => formatFinancingPlan(plan), [plan]);
    const pricePerMonth: Dinero = plan?.threshold
        ? getMonthlyPrice(plan.threshold, plan.length)
        : getDinero(0.0);
    return (
        <section className="pre-qual-block">
            <div className="u-flex-container">
                <section>
                    <img
                        className="lazyload responsive-img"
                        alt={t`Tempur-Pedic Credit Card`}
                        data-src={tempurCreditCardUpdated}
                    />
                </section>
                <section className="pre-qual-block__content">
                    <h6>{t`NO FEES, NO SURPRISES`}</h6>
                    <h1>
                        {t`Sleep Soundly with ${formattedPlan.apr} APR for ${formattedPlan.length} Months`}
                        <Link href={urls.pageURL("finance-link")}>
                            <sup>1</sup>
                        </Link>
                    </h1>
                    <ul>
                        <li>
                            Minimum purchase of {formattedPlan.threshold} or{" "}
                            {format.money(pricePerMonth)}/{t`mo`}
                            <Link href={urls.pageURL("finance-link")}>
                                <sup>2</sup>
                            </Link>{" "}
                            for {formattedPlan.length} months
                            <Link href={urls.pageURL("finance-link")}>
                                <sup>1</sup>
                            </Link>
                            <br />
                            (total payments of {formattedPlan.threshold})
                        </li>
                        <li>
                            Check if you pre-qualify without impacting your
                            credit score
                        </li>
                        <li>Apply now or during checkout</li>
                    </ul>
                    <div className="pre-qual-block__buttons">
                        <FinancingModalTrigger
                            modalType="prequal-app"
                            className="button al-HP-pre-qual-block__button--pre-qualify"
                            applicationSource={props.applicationSource}
                        >
                            {t`Check if You Pre-Qualify`}
                        </FinancingModalTrigger>
                        <FinancingModalTrigger
                            modalType="full-app"
                            className="button button--secondary al-HP-pre-qual-block__button--apply-now"
                            applicationSource={props.applicationSource}
                        >
                            {t`Apply Now`}
                        </FinancingModalTrigger>
                    </div>
                    <Link
                        className="pre-qual-block__learn-more"
                        href={urls.pageURL("finance-link-top")}
                        target="_top"
                    >
                        Learn More
                    </Link>
                </section>
            </div>
        </section>
    );
};
